import * as React from "react"
import { graphql } from 'gatsby'
import Gallery from '@browniebroke/gatsby-image-gallery'

import Seo from "../components/seo"

const MyGallery = ({ data }) => {

  const lightboxOptions = {
    imageLoadErrorMessage: 'Impossible de charger cette image',
    nextLabel: 'Image suivante',
    prevLabel: 'Image précédente',
    zoomInLabel: 'Zoomer',
    zoomOutLabel: 'Dézoomer',
    closeLabel: 'Fermer',
  }


  const images = data.allFile.edges.map(({ node }) => ({
    ...node.childImageSharp,
    // Use original name as caption.
    // The `originalName` is queried in a nested field,
    // but the `Gallery` component expects `caption` at the top level.
    caption: node.childImageSharp.meta.originalName,
  }))
  // const images = data.allFile.edges.map(({ node }) => node.childImageSharp)

  return (
    <>
      <Seo title="Galerie" />
      <div className="mx-auto max-w-xl md:max-w-7xl">
        <h2 className="font-semibold font-lator mt-4 text-center mb-6 leading-none tracking-tighter text-3xl text-gray-900 sm:text-4xl">Notre Galerie</h2>
        <Gallery
          images={images}
          lightboxOptions={lightboxOptions}
          colWidth={25}
          // mdColWidth={33}
          // gutter={0.0}
          rowMargin={-30}
        />
      </div>
    </>
  )
}

export const pageQuery = graphql`
  query ImagesForGallery {
    allFile(filter: {relativeDirectory: {eq: "gallery"}}
    sort: { fields: name }
    ) {
      edges {
        node {
          childImageSharp {
            thumb: gatsbyImageData(
              width: 270
              height: 270
              placeholder: BLURRED
            )
            full: gatsbyImageData(layout: FULL_WIDTH)
            meta: fixed {
              originalName
            }
          }
        }
      }
    }
  }
  `
export default MyGallery
